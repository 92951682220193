.truncate {
  max-width: 126px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chevron img {
  transition: all 0.3s;
  transform: rotate(-90deg);
}
.chevron-rotated img {
  transform: rotate(0deg);
  transition: all 0.3s;
}
.light .table-title {
  color: #000;
}
.dark .table-title {
  color: #fff;
}

@media screen and (max-width: 576px) {
  .table-accordion-wrapper {
    width: 95.1% !important;
  }
}

.table-accordion-wrapper {
  width: 94.8%;
  border-left: 2px solid #374151;
  border-bottom: 2px solid #374151;
  border-top: 2px solid #374151;
  border-right: 1px solid #374151;
  margin-left: auto;
}
.light .table-accordion-wrapper {
  border-left: 2px solid #e5e7eb;
  border-bottom: 2px solid #e5e7eb;
  border-top: 2px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
}
