form.change-password-form label {
  color: var(--text-color);
}

form.change-password-form .form-control {
  background-color: var(--bg-surface);
}
form.change-password-form .form-control:focus {
  background-color: var(--bg-surface);
}

.change-password-content h5,
.change-password-content p {
  color: var(--text-color);
}
