.device-otp-form-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
.device-otp-form-group .input-field {
  flex: 4;
}
.device-otp-form-group .input-field input {
  height: 50px;
  background-color: var(--bg-surface-strong);
}
.device-otp-form-group .button-field {
  flex: 3;
}
.device-otp-form-group .button-field button {
  width: 100%;
  height: 50px;
}
