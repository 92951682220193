.table-recent-trades-wrapper {
  height: 160px;
}

.settings-modal-action-btn {
  background-color: var(--bg-primary);
  color: #fff;
}
.settings-modal-action-btn:hover {
  background-color: var(--bg-primary);
  color: #fff;
}

.table-recent-trades-wrapper thead {
  position: relative;
}

@media screen and (min-width: 1024px) {
  .table-recent-trades-wrapper thead {
    display: block;
  }
  .table-recent-trades-wrapper table tbody {
    table-layout: fixed;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 90%;
    height: 150px;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.table-trade-wrapper {
  height: 325px;
}

table {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}
table thead {
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
}
table tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
table tbody tr {
  width: 100%;
}
table thead,
table tbody tr {
  display: table;
  table-layout: fixed;
}

.table-sort-icon.rotated img {
  transform: rotate(180deg);
  transition: all 0.3s;
}
.table-sort-icon.rotated img {
  transition: all 0.3s;
}
.table-sort-icon img {
  transition: all 0.3s;
}
.table-sort-icon.hidden {
  display: none;
}

@media screen and (min-width: 780px) {
  .table-recent-trades-wrapper table td:nth-child(1),
  .table-recent-trades-wrapper table th:nth-child(1) {
    min-width: 80px;
    width: 80px;
  }
  .table-recent-trades-wrapper table td:nth-child(2),
  .table-recent-trades-wrapper table th:nth-child(2) {
    min-width: 80px;
    width: 80px;
  }
  .table-recent-trades-wrapper table td:nth-child(3),
  .table-recent-trades-wrapper table th:nth-child(3) {
    min-width: 80px;
    width: 80px;
  }
}

.table-trade-history .table-accordion-wrapper {
  width: 95.1%;
  border-left: 1px solid #374151;
  border-bottom: 1px solid #374151;
  border-top: 1px solid #374151;
  border-right: 0;
  margin-left: auto;
}

.table-recent-trades-wrapper table td {
  font-size: 12px;
}

@media screen and (max-width: 578px) {
  .table-recent-trades-wrapper th {
    min-width: 33vw !important;
    width: 33vw !important;
  }
  .table-recent-trades-wrapper td {
    min-width: 33vw !important;
    width: 33vw !important;
  }
  /*
  .table-recent-trades-wrapper td:nth-child(2), th:nth-child(2) {
    min-width: 100px;
    width: 100px;
  }
  .table-recent-trades-wrapper td:nth-child(3), th:nth-child(3) {
    min-width: 100px;
    width: 100px;
  } */
}

.scrollbar-custom {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scrollbar-custom table tbody {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.table-recent-trades-wrapper table tbody {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.table-recent-trades-wrapper table tbody::-webkit-scrollbar {
  width: 6px;
  display: none;
}

.table-recent-trades-wrapper table tbody::-webkit-scrollbar-track {
  background: #1c2734;
}

.table-recent-trades-wrapper table tbody::-webkit-scrollbar-thumb {
  background-color: rgb(35, 56, 118);
}

.table-recent-trades-wrapper table tbody::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbar-custom::-webkit-scrollbar {
  display: none;
}

.table-recent-trades-wrapper tr {
  width: 100%;
}

.pair-card {
  border: 1px solid #fff;
  border-radius: 6px;
  color: #fff;
  margin-left: 16px;
  padding: 5px 16px;
  margin-top: 10px;
  cursor: pointer;
}
.light .pair-card {
  border: 1px solid #e5e7eb;
  color: var(--text-color);
}
.pair-card.selected {
  background-color: var(--bg-primary);
  color: #fff;
  border: 1px solid var(--bg-primary);
}

.order-book {
  display: flex;
  flex-direction: column;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #ffffff;
  margin: auto;
}

@media screen and (min-width: 1024px) {
  .order-book {
    max-width: 350px;
  }
}

.order-section {
  background-color: #121212; /* Dark background for the entire section */
}

.order-table {
  margin: 10px;
}

.table-header,
.table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: right;
  padding: 5px 10px;
}

.table-header {
  font-size: 0.9em;
  color: #aaa; /* Light gray for header text */
  border-bottom: 1px solid #333; /* Separator line */
}

.table-row {
  font-size: 0.9em;
  line-height: 1.5; /* Increased line height for better readability */
}

.table-row span {
  padding: 2px 0; /* Reduced padding */
}

.h2 {
  background-color: #1e1e1e; /* Slightly lighter dark shade for headings */
  padding: 10px;
  margin: 0;
  font-size: 1.1em;
  text-align: center;
  color: #76ff03; /* Bright green */
}

/* Additional styling for visual separation and emphasis */
.sell-row {
  background-color: #ff6347; /* Tomato red for sell orders */
}

.buy-row {
  background-color: #32cd32; /* Lime green for buy orders */
}

.bg-navy {
  background-color: #1c2734;
}

.border-secondary {
  border-color: #353b47 !important;
}

.theme_no_2 .announce-bar {
  background-color: var(--bg-primary);
}
.theme_no_3 .announce-bar {
  background-color: #61c6b2;
}

.announce-bar {
  padding-left: 20px;
  padding-right: 20px;
  transition: all 0.3s ease;
  background-color: #233876;
}

.announce-bar.visible {
  height: 42px;
}
.announce-bar.hidden {
  height: 0px;
}

.light .dropdown ul.drop-menu {
  background-color: #fff;
}

.dropdown .drop-menu {
  top: 50px;
  background-color: #121928;
  z-index: 20;
  height: calc(100vh - 165px);
  overflow: scroll;
}
@media screen and (min-width: 760px) {
  .dropdown .drop-menu {
    width: 484px;
  }
}

@media screen and (max-width: 576px) {
  .dropdown .drop-menu {
    top: 55px;
    background-color: #121928;
    z-index: 20;
    height: calc(100vh - 79px);
    overflow: scroll;
    left: -33px;
    width: 95vw;
  }
}

.blured {
  filter: blur(5px);
}

.coin-slider .coin-item {
  width: 100px;
  border: 1px solid #3a3a47;
  border-radius: 6px;
  padding: 10px;
}

.light .coin-slider .coin-item {
  border: 1px solid #e5e7eb;
}

.dropdown-searchbox .search-icon {
  position: absolute;
  left: 25px;
  top: 9px;
}
.dropdown-searchbox input {
  width: 100%;
  padding-left: 35px;
  color: var(--text-color);
  border: 1px solid #4b5563;
  border-radius: 8px;
  background-color: #374151;
  height: 36px;
}

.light .dropdown-searchbox input {
  background-color: var(--bg-surface);
  border: 1px solid #e5e7eb;
}

.dropdown .btn:first-child:active {
  border-color: transparent !important;
}
.dropdown .btn::after {
  display: none;
}
.drop-menu .btn:hover {
  background-color: rgb(35, 56, 118);
}

.book-indicator {
  width: 12px;
  height: 5px;
}

.order-book .table td {
  padding-left: 0;
  padding-right: 0;
}

.table-no-border thead,
.table-no-border tbody,
.table-no-border tr,
.table-no-border th,
.table-no-border td {
  border: none !important;
}

.table-order-book td {
  font-size: 12px;
}

.guest-warn {
  background-color: #374151;
  border-radius: 8px;
  margin-top: 10px;
  width: 100%;
  height: 39px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-open-orders .trade-btn {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: #fff;
  display: flex;
  align-items: center;
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 8px 12px;
  width: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-open-orders td {
  padding: 16px;
  border-right: 1px solid #374151;
  border-bottom: none;
  vertical-align: middle !important;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}

.light .table-open-orders td {
  color: #000;
  border-right: 1px solid #e5e7eb;
}

.table-open-orders td:last-child {
  border-right: 0;
}

.table-open-orders th {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 26px;
  border: none;
}

@media screen and (max-width: 560px) {
  table.table-open-orders th {
    padding-left: 0;
    padding-right: 0;
  }
}

table tbody.table-border {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #353b47 !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

.table-open-orders .date span {
  color: #4b5563;
  margin-left: 10px;
}

.buyorder-input,
.sellorder-input {
  background-color: #374151;
  border: 1px solid #4b5563;
  border-radius: 8px;
  height: 39px;
  padding-left: 50px;
  padding-right: 60px;
  color: #fff;
  width: 100%;
  font-size: 12px;
  text-align: right;
}
.buyorder-inputgroup .label-price,
.sellorder-inputgroup .label-price {
  top: 11px;
  left: 10px;
  font-size: 12px;
}
.buyorder-inputgroup,
.sellorder-inputgroup {
  margin-top: 10px;
}
.buyorder-dropdown > button,
.sellorder-dropdown > button {
  background-color: #374151;
  border: 1px solid #4b5563;
  color: #fff;
  border-radius: 8px;
  height: 39px;
  margin-top: 10px;
  width: 100%;
}
.buyorder-dropdown {
  z-index: 10;
}
.buyorder-options,
.sellorder-options {
  background-color: #374151;
  border: 1px solid #4b5563;
  width: 100%;
  border-radius: 8px;
  top: 55px;
}
.buyorder-options button,
.sellorder-options button {
  background-color: transparent;
  border: 0;
  color: var(--text-color-strong);
  height: 39px;
  width: 100%;
  text-align: left;
}
.buyorder-options button:hover,
.sellorder-options button:hover {
  background-color: #101928;
}
.buyorder-options li:first-child button,
.sellorder-options li:first-child button {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.buyorder-options li:last-child button,
.sellorder-options li:last-child button {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.buyorder-options,
.sellorder-options {
  display: none;
}
.buyorder-options.show,
.sellorder-options.show {
  display: block;
  z-index: 10;
}
.buyorder-inputgroup .label-coin,
.sellorder-inputgroup .label-coin {
  top: 11px;
  right: 15px;
  font-size: 12px;
}
.buyorder-action,
.sellorder-action {
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  height: 41px;
}

.buyorder-form .is-invalid,
.sellorder-form .is-invalid {
  border: 1px solid #dc3545;
}

.sellorder-action {
  color: #fff;
}

.percentages {
  margin: 20px 0;
}
.percentage-ball {
  width: 15px;
  height: 15px;
  background-color: #374151;
}
.percentage-ball.active {
  background-color: #198754;
}

.sellorder-form .percentage-ball.active {
  background-color: #eb5757;
}

.percentage-ball.current {
  border: 1px solid #198754;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sellorder-form .percentage-ball.current {
  border: 1px solid #eb5757;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sellorder-form .percentage-ball.current::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #eb5757;
  border-radius: 100%;
}
.percentage-ball.current::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #198754;
  border-radius: 100%;
}
.percentage-bar {
  width: 27px;
  height: 4px;
  background-color: #374151;
  margin-left: 4px;
}
.sellorder-form .percentage-bar.active {
  background-color: #eb5757;
}
.percentage-bar.active {
  height: 4px;
  background-color: #198754;
}
.custom-radio-container:last-child .percentage-bar {
  display: none;
}
.percentage-slider.sell .rc-slider-handle::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #eb5757;
  border-radius: 100%;
  display: block;
}

.rc-slider-handle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-slider-handle::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #198754;
  border-radius: 100%;
  display: block;
}

.rc-tooltip-inner {
  background-color: #374151 !important;
}

/* Trading view */
.trading-view {
  background-color: #121928;
}
.light .trading-view {
  background-color: #fff;
}

/* Helper styles */
.text-xs {
  font-size: 12px;
}
