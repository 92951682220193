input.form-control {
  background-color: #374151;
  border: none !important;
}
input.form-control option:checked {
  background-color: #374151; /* Selected option background color */
}
input.form-control:focus {
  background-color: #374151;
  border: none !important;
}

h3 span,
.deposits-page h3,
.deposits-info li,
.deposit-address {
  color: var(--text-color-strong);
}
.form-control:disabled {
  background-color: var(--bg-surface-strong);
}

.css-13cymwt-control {
  background-color: #374151 !important;
}
.css-1fdsijx-ValueContainer {
  background-color: #374151 !important;
}
.css-1nmdiq5-menu {
  background-color: #374151 !important;
}

.table-deposits .table-accordion-wrapper {
  width: 94.6%;
  border-left: 2px solid #374151;
  border-bottom: 2px solid #374151;
  border-top: 2px solid #374151;
  border-right: 1px solid #374151;
  margin-left: auto;
}
.light .table-accordion-wrapper {
  border-left: 2px solid #e5e7eb;
  border-bottom: 2px solid #e5e7eb;
  border-top: 2px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
}
