.settings-modal .modal-content {
  width: 94vw;
  max-width: 416px;
  border-radius: 8px;
}

.dark .settings-modal .modal-content {
  background-color: #202a38;
  border: 1px solid #4b5563;
}

.light .settings-modal .modal-content {
  background-color: var(--bg-surface-strong);
  border: 1px solid var(--bg-surface);
}

.settings-modal .modal-title {
  font-size: 28px;
  color: var(--text-color);
}

.settings-modal .dropdown-title {
  color: var(--text-color);
}

.settings-modal div.buyorder-dropdown button {
  color: var(--text-color);
}

.settings-modal .btn-close-modal:focus {
  outline: none;
  box-shadow: none;
}

.modal.settings-modal .active-dropdown {
  z-index: 20 !important;
}

.active-dropdown {
  z-index: 20;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
}

.modal-backdrop.open {
  filter: blur(5px);
}
