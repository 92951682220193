/* CSS File */
body {
 font-family: Arial, sans-serif;
 overflow-x: hidden;
}

.table-responsive.table-home table td:first-child {
  width: 100px;
  max-width: 100px;
}
.table-responsive.table-home table th:first-child {
  width: 100px;
  max-width: 100px;
}
.table-responsive.table-home table {
  margin-bottom: 0;
}
