.hh-accordion .accordion-button:not(.collapsed) {
  background-color: var(--bg-surface);
  border-color: var(--bg-surface);
  color: var(--text-color);
  box-shadow: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.hh-accordion .accordion-button:not(.collapsed) svg {
  transform: rotate(0deg);
  transition: all 0.3s;
}
.affiliate-accordion .accordion-body {
  background-color: var(--bg-surface);
}
.affiliate-accordion button {
  background-color: var(--bg-surface);
  border-color: var(--bg-surface);
  color: var(--text-color);
  box-shadow: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.affiliate-accordion button svg {
  transform: rotate(180deg);
  transition: all 0.3s;
}
.affiliate-accordion button svg path {
  fill: var(--text-color);
}
.affiliate-accordion button::after {
  display: none;
}
.affiliate-accordion button:focus {
  box-shadow: none;
  border: 0;
}
.affiliate-accordion.accordion {
  background-color: var(--bg-surface);
  --bs-accordion-bg: var(--bg-surface);
}

.light .main-page .main-content .affiliate-accordion .pagination li:hover a {
  color: #000;
}
