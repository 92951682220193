.btn.btn-primary {
  background-color: var(--bg-primary);
  border-color: var(--bg-primary);
}

.mobile-menu {
  position: fixed;
  left: -292px;
  top: 0;
  background-color: #1f2a37;
  border-right: 1px solid #374151;
  height: 100vh;
  width: 75vw;
  transition: left 0.3s ease;
  padding: 30px 20px 20px 20px;
  z-index: 30;
}
.light .mobile-menu {
  background-color: var(--bg-surface);
  border-right: 1px solid var(--bg-surface);
}
.mobile-menu.open {
  display: block;
  left: 0;
}
.mobile-menu .button-close-menu {
  position: absolute;
  top: 1%;
  right: 6%;
  background-color: transparent;
  border: 0;
  width: 20px;
  height: 20px;
  display: block;
}

.mobile-menu .actions .button-login {
  padding: 8px 12px;
  margin-right: 12px;
  border: 1px solid var(--bg-primary);
  background: var(--bg-primary);
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: #fff;
  transition: all ease-in-out 300ms;
}

.mobile-menu .actions .button-signUp {
  padding: 8px 12px;
  margin-right: 12px;
  border: 1px solid var(--bg-primary);
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--bg-primary);
  transition: all ease-in-out 300ms;
}

.mobile-menu .actions .button-signUp:hover {
  box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  transform: translate(0px, -4px) scale(1.01);
}

.mobile-menu .actions .button-login:hover {
  box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  transform: translate(0px, -4px) scale(1.01);
}

.mobile-menu .menu li {
  margin-bottom: 16px;
}

.mobile-menu .menu li a {
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #374151;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}
.light .mobile-menu .menu li a {
  background-color: var(--bg-surface-strong);
  color: #121928;
}
.mobile-menu .button-download {
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #374151;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}
.mobile-menu .button-download img {
  margin-right: 8px;
}

@media screen and (max-width: 1024px) {
  .mobile-menu {
    display: none;
  }
}

.mobile-menu .button-setting {
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #374151;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  margin-top: 16px;
}
.light .mobile-menu .button-setting {
  background-color: var(--bg-surface-strong);
  color: #121928;
}

.mobile-menu .menu li a span {
  margin-left: 8px;
}

.mobile-menu .button-logOut {
  padding: 8px 12px;
  margin-right: 12px;
  border: 1px solid #f98080;
  background: transparent;
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: #f98080;
  transition: all ease-in-out 300ms;
}
