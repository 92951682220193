.e-otc-orders-table th,
.e-otc-orders-table td {
  text-align: left;
  min-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dark .e-otc .button {
  border: 1px solid white;
  color: white;
  background: transparent;
}
.dark .e-otc .button:hover {
  background: white;
  color: black;
}
.light .e-otc .button {
  border: 1px solid black;
  color: black;
  background: transparent;
}
.light .e-otc .button:hover {
  background: black;
  color: white;
}
.dark .e-otc .button:disabled {
  background: black;
  color: #666;
  cursor: not-allowed;
}

.dark .e-otc .button:disabled:hover {
  background: black;
  color: #666;
}

.light .e-otc .button:disabled {
  background: white;
  color: #666;
  cursor: not-allowed;
}

.light .e-otc .button:disabled:hover {
  background: white;
  color: #666;
}
.e-otc .paginationButton {
  padding: 7px 15px;
  border-radius: 5px;
}

.light .e-otc .executeBtn {
  background-color: black;
  color: white;
}

.light .rfq-quantity-input {
  color: black;
}

.dark .rfq-quantity-input {
  color: white;
}
