.form-control {
  background-color: #121928;
  border: none !important;
  color: #fff;
}
.form-control:focus {
  background-color: #121928;
  border: none !important;
  box-shadow: none !important;
  color: #fff;
}
.light .withdrawals .form-control {
  background-color: var(--bg-surface-strong);
  border: none !important;
  color: #000;
}
.light .withdrawals .form-control:focus {
  background-color: var(--bg-surface-strong);
  border: none !important;
  color: #000;
}

.form-control:disabled {
  background-color: var(--bg-surface-strong);
}

.withdrawals .form-group label {
  color: #fff;
}
.light .withdrawals .form-group label {
  color: #000;
}
.form-control option:checked {
  background-color: #374151; /* Selected option background color */
}

.css-13cymwt-control {
  background-color: #374151 !important;
}
.css-1fdsijx-ValueContainer {
  background-color: #374151 !important;
}
.css-1nmdiq5-menu {
  background-color: #374151 !important;
}

.btn.button-danger:hover {
  background-color: #f05252;
  color: #fff;
}
.btn.button-danger:active {
  background-color: #f05252;
  color: #fff;
}

.css-13cymwt-control {
  border: 0 !important;
  padding-left: 1px;
}

.light .withdrawals-dropdown .css-1nmdiq5-menu .css-g738tf-option {
  background-color: var(--bg-surface-strong);
  border-top: 0;
  color: #000;
}

.withdrawals-dropdown {
  z-index: 20;
}

.light .withdrawals-dropdown .css-1nmdiq5-menu {
  z-index: 20;
}

.light .withdrawals-dropdown .css-1bxi5ox-singleValue {
  color: #000;
}
.light .withdrawals-dropdown .css-1jqq78o-placeholder {
  color: #000;
}

.dark .withdrawals-dropdown .css-1dimb5e-singleValue {
  color: #fff;
}
.dark .withdrawals-dropdown .css-1bxi5ox-singleValue {
  color: #fff;
}
.dark .withdrawals-dropdown .css-1jqq78o-placeholder {
  color: #fff;
}

.dark .css-15lsz6c-indicatorContainer {
  background-color: #374151 !important;
  color: #fff;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.dark .css-1xc3v61-indicatorContainer {
  background-color: #374151 !important;
  color: #fff;
}

.light .css-15lsz6c-indicatorContainer {
  background-color: var(--bg-surface-strong) !important;
}

.light .css-1xc3v61-indicatorContainer {
  background-color: var(--bg-surface-strong) !important;
  color: #121928;
}

.withdrawals-dropdown .custom-select input {
  color: var(--text-color-strong) !important;
}
.withdrawals-dropdown .custom-select input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.css-1fdsijx-ValueContainer:focus {
  box-shadow: none !important;
  outline: none !important;
}

.css-t3ipsp-control {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  background-color: transparent !important;
  border-radius: 4px !important;
}

.withdrawals-dropdown .css-b62m3t-container {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
}

.light .withdrawals-dropdown .css-1fdsijx-ValueContainer {
  background-color: var(--bg-surface-strong) !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid transparent !important;
}
.light .css-13cymwt-control {
  background-color: var(--bg-surface-strong) !important;
}

@media screen and (min-width: 768px) {
  .main-page.withdrawals .main-content {
    padding: 26px;
    margin: 26px;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .main-page.withdrawals .main-content {
    padding: 26px;
    margin: 20px 0;
  }
}

.main-page.withdrawals .main-content {
  background-color: var(--bg-surface);
}

.table-withdrawals .table-accordion-wrapper {
  width: 94.6%;
  border-left: 2px solid #374151;
  border-bottom: 2px solid #374151;
  border-top: 2px solid #374151;
  border-right: 1px solid #374151;
  margin-left: auto;
}
.light .table-accordion-wrapper {
  border-left: 2px solid #e5e7eb;
  border-bottom: 2px solid #e5e7eb;
  border-top: 2px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
}
