.convert-funds-content p {
  color: var(--text-color);
}

.dark .convert-funds-content p strong {
  color: #fff;
}

.light .convert-funds-content p strong {
  color: #000;
}
